<template>
  <tr v-if="mode === 'pc'" :class="{ my: isMyData }" @click="showRankModal">
    <td>
      <img v-if="ranking <= 3" :src="`/img/pages/icon/Rank_${ranking}.svg`" class="top-rank" />
      <div v-else-if="isMyData" class="my-rank">
        <em>{{ ranking }}</em>
        <img src="/img/pages/icon/Rank_My.svg" />
      </div>
      <em v-else>{{ ranking }}</em>
    </td>
    <td v-if="data.id === 'university'">
      <div class="team-name">
        {{ universityName }}
      </div>
      <!-- <div :class="['team-name', { 'with-flag': ranking <= 3 || isMyData }]">
        {{ universityName }}
      </div>
      <span v-if="ranking <= 3 || isMyData" :class="['flag', { 'my-flag': isMyData }]">
        {{ isMyData ? `MY` : `현재 ${ranking}위` }}
      </span> -->
    </td>
    <td v-else>
      <div v-if="data.id === 'levelup-team'" class="team-name with-flag">
        <ImgLogo
          :width="51"
          :height="48"
          :title="userNickName"
          :hash="userId"
          :source="userImage"
          :custom-class="'team-logo'"
        />
        {{ userNickName }}
      </div>
      <div v-else class="team-name with-flag">
        <ImgLogo
          :width="51"
          :height="48"
          :title="teamName"
          :hash="teamId"
          :source="teamImage"
          :custom-class="'team-logo'"
        />
        {{ teamName }}
      </div>
      <span v-for="university in universities" :key="university.universityId" class="flag college-flag name">
        {{ university.name }}
      </span>
      <span v-if="universities.length > 1" class="flag college-flag number">
        {{ `+ ${universities.length - 1}` }}
      </span>
    </td>
    <td v-if="data.id === 'university'">{{ teamCount }}팀</td>
    <td>{{ applyCount }}회</td>
    <td>
      {{ winOrloseCnt }}
    </td>
    <td class="point">{{ mmrPoint || point }}P</td>
    <td v-if="this.data.id !== 'levelup-team'" :class="['rank-change', { up: changeRank > 0, down: changeRank < 0 }]">
      <span>{{ Math.abs(changeRank) || '' }}</span>
    </td>
  </tr>
  <li v-else :class="{ my: isMyData }" @click="showRankModal">
    <div class="rank-info">
      <img v-if="ranking <= 3" :src="`/img/pages/icon/Rank_${ranking}.svg`" class="top-rank" />
      <div v-else-if="isMyData" class="my-rank">
        <em>{{ ranking }}</em>
        <img src="/img/pages/icon/Rank_My.svg" />
      </div>
      <em v-else>{{ ranking }}</em>
    </div>
    <div class="team-info">
      <div class="main">
        <div class="subject">
          <p v-if="data.id === 'university'">{{ universityName }}</p>
          <p v-else-if="data.id === 'levelup-team'">{{ userNickName }}</p>
          <p v-else>{{ teamName }}</p>
        </div>
        <div v-if="universities.length" class="flag college-flag">
          <span>
            {{ universities[0].name.replace('학교', '') }}
          </span>
        </div>
        <span
          v-if="data.id !== 'levelup-team'"
          :class="['rank-change', { up: changeRank > 0, down: changeRank < 0 }]"
          >{{ Math.abs(changeRank) || '' }}</span
        >
      </div>
      <div class="sub">
        <ul>
          <li>
            <span class="label">승패</span>
            <span>{{ winOrloseCnt }}</span>
          </li>
          <li>
            <span :class="['label', { red: mmrPoint }]">{{ mmrPoint ? '레벨업 MMR' : '포인트' }}</span>
            <span>{{ mmrPoint || point }} P</span>
          </li>
        </ul>
      </div>
    </div>
  </li>
</template>

<script>
import { commaDecimal } from '@/utils';
import ImgLogo from '@/views/components/common/ImgLogo.vue';
import RankModal from '@/views/components/leaderboard/RankModal.vue';

export default {
  name: 'RankList',
  components: { ImgLogo },
  props: {
    mode: {
      type: String,
      default: 'pc',
    },
    data: {
      type: Object,
      default: () => {},
    },
    list: {
      type: Object,
      default: () => {},
    },
    leagueInfo: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    skeleton() {
      return Boolean(this.list.stats);
    },
    isMyData() {
      return Boolean(this.list.myData);
    },
    ranking() {
      return this.list.ranking;
    },
    teamId() {
      return this.list.team.teamId;
    },
    teamName() {
      return this.list.team.name;
    },
    teamImage() {
      return this.list.team.imageUrl;
    },
    universityName() {
      return this.list.university.name;
    },
    universities() {
      return this.list.universities || [];
    },
    userId(){
      return this.list.user ? this.list.user.userId : null
    },
    userNickName(){
      return this.list.user ? this.list.user.nickname : null
    },
    userImage(){
      return this.list.user ? this.list.user.profileImage : null
    },
    teamCount() {
      return commaDecimal(this.list.stats.teamCount);
    },
    applyCount() {
      return commaDecimal(this.list.stats.applyCount);
    },
    winOrloseCnt() {
      return `${this.list.stats.winCount}승 ${this.list.stats.loseCount}패`;
    },
    point() {
      return this.list.stats.point !== undefined ? commaDecimal(this.list.stats.point) : null;
    },
    mmrPoint() {
      return this.list.stats.mmrPoint !== undefined ? commaDecimal(this.list.stats.mmrPoint) : null;
    },
    changeRank() {
      return this.list.stats.previousRanking - this.ranking;
    },
  },
  methods: {
    showRankModal() {
      if (this.data.id !== 'university') return;

      this.$modal(RankModal, { college: this.list, leagueCode: this.leagueInfo.leagueCode });
    },
  },
};
</script>

<style lang="less" scoped></style>
