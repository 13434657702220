<template>
  <div league-list>
    <div class="img-con">
      <ImageAsset :url="logoUrl || '/img/asset/Poster_Default.jpg'" :class="{'default-image':!logoUrl}"
        :alt="'league-image'" />
    </div>
    <div class="infor-con">
      <div class="title">
        <h5>{{ title }}</h5>
        <ButtonRipple v-if="selectedLeague" class="detail-btn" @click="leagueListsShow">
          <span></span>
          <span></span>
          <span></span>
        </ButtonRipple>
      </div>
      <div class="date">
        <span>{{ startDate }} ~ {{ endDate }}</span>
        <span v-if="isOnGoing" class="ing-status">진행중</span>
      </div>
    </div>
  </div>
</template>

<script>
import { customDateFormat } from '@shared/utils/timeUtils';
import ImageAsset from '@/views/components/common/ImageAsset.vue';
import ButtonRipple from '@/views/components/common/button/ButtonRipple.vue';
import LeagueModal from '@/views/components/leaderboard/LeagueModal.vue';

export default {
  name: 'LeagueList',
  components: { ImageAsset, ButtonRipple },
  props: {
    selectedLeague: {
      type: Object,
      default: () => {
      },
    },
    league: {
      type: Object,
      default: () => {
      },
    },
  },
  computed: {
    title() {
      return this.league.title || '-';
    },
    logoUrl() {
      return this.league.logoUrl;
    },
    startDate() {
      return this.league.startDateTime ? customDateFormat(this.league.startDateTime, 'YYYY.MM.DD') : '0000.00.00';
    },
    endDate() {
      return this.league.endDateTime ? customDateFormat(this.league.endDateTime, 'YYYY.MM.DD') : '0000.00.00';
    },
    isOnGoing() {
      return Date.now() > this.league.startDateTime && Date.now() < this.league.endDateTime;
    },
  },
  methods: {
    async leagueListsShow() {
      const { selectedLeague } = this;
      const leagueInfo = await this.$modal(LeagueModal, { selectedLeague });

      this.$emit('select-league', leagueInfo);
    },
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[league-list] { .rel; .p(10); .-a(rgba(0, 0, 0, 0.2)); .br(4);
  .img-con { .abs; .lt(0, -2); .z(1); .wh(120, 120); .hide;
    [image-asset] { .wh(100%, 100%); .br(0);
      &.default-image {
        > img { filter: gray; -webkit-filter: grayscale(100%); }
      }
    }
  }
  .infor-con { .pl(0);
    .title { .rel; .mb(15);
      h5 { .flex-ai(flex-start); .flex-jc(center); flex-wrap: wrap; flex-direction: column; .h(36); .pr(40); .fs(16); .c(#000); .ellipsis(2); .flex; }
      .detail-btn { .abs; .rt(0, 0); z-index: 1; .wh(36, 36); .bgc(#f3f3f6); .br(4);
        > span { .block; .mh-c; .mb(5); .wh(19, 2); .bgc(#000);
          &:last-child { .mb(0); }
        }
      }
    }
    .date { .mb(10);
      > span { .ib;
        &:nth-of-type(1) { .pl(27); .bg('@{icon}/Arena_date.svg'); background-position: left 0px; .bg-w(18); .no-repeat; .fs(14); }
        &.ing-status { .wh(50, 24); .ml(8); .fs(12, 24); .c(#7356bf); .tc; .bgc(#e3ddf2); .-a(rgba(105, 79, 174, 0.1)); .br(4); }
      }
    }
  }

  @media (@tl-up) {
    .crop; .h(120); .p(12);
    .img-con { .block; }
    .infor-con { .pl(120);
      .title { .mt(5); .mb(10);
        h5 { .flex-jc(flex-start); .h(60); .pr(55); .fs(24); }
        .detail-btn { .rt(6, 3); }
      }
      .date {
        > span {
          &.ing-status { .ml(16); }
        }
      }
    }
  }

  @media (@ds-up) {

  }
}
</style>
