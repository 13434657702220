<template>
  <div official-league-rank>
    <div class="control-box">
      <div :class="['toggle-tab-con', { summoner: tabs.length === 3 }]">
        <ToggleTab v-model="officialTabIndex" :tabs="tabs" @input="toggleTab" />
      </div>
      <div v-if="officialTabIndex < 2" class="search-con">
        <SearchInput v-model="searchInp" :placeholder="'검색어를 입력해주세요.'" @click="searchData" />
      </div>
    </div>
    <div v-if="officialTabIndex !== 2" class="tooltip-con">
      <span>배틀아카데미아 랭킹 정보</span>
      <ToolTip image="/img/pages/icon/ico-question-mark-red.svg">
        * 해당 포인트는 대학 리그전 전용 포인트입니다. 소속한 대학으로 팀으로 획득한 포인트가 합산 되어 적용됩니다. <br /> <br /> - 승리시 3점<br /> - 패배시 1점<br /> - 3연승 시 보너스 1점<br /> - 체크인 후 연락두절 -2점 <br /> <br /> * 순위는 매일 매칭 종료 후 수 분 내에 업데이트 됩니다.
      </ToolTip>
    </div>
    <CollegeRank
      v-if="officialTabIndex === 0"
      :data="tabDatas[0]"
      :loading="loading"
      :league-info="leagueInfo"
      :search-inp="searchInp"
      @more-load="moreLoadData"
    />
    <TeamRank
      v-else-if="officialTabIndex === 1"
      :data="tabDatas[1]"
      :loading="loading"
      :league-info="leagueInfo"
      :search-inp="searchInp"
      @more-load="moreLoadData"
    />
    <SummonerHall v-else-if="officialTabIndex === 2" :data="tabDatas[2]" />
  </div>
</template>

<script>
import gtag from '@shared/mixins/gtag';
import ToggleTab from '@/views/components/common/tab/ToggleTab.vue';
import SearchInput from '@/views/components/common/input/SearchInput.vue';
import ToolTip from '@/views/components/common/ToolTip.vue';
import CollegeRank from '@/views/components/leaderboard/official-league-rank/CollegeRank.vue';
import TeamRank from '@/views/components/leaderboard/official-league-rank/TeamRank.vue';
import SummonerHall from '@/views/components/leaderboard/official-league-rank/SummonerHall.vue';

export default {
  name: 'OfficialLeagueRank',
  mixins: [gtag],
  components: { ToggleTab, SearchInput, ToolTip, CollegeRank, TeamRank, SummonerHall },
  data() {
    return {
      loading: false,
      searchInp: '',
      officialTabIndex: 2,
      tabDatas: [
        {
          id: 'university',
          thead: ['순위', '대학명', '팀 수', '참가 횟수', '승패', '획득 포인트', 'rankChange'],
          cursor: 0,
          size: 10,
          hasNext: false,
          totalCount: 0,
          myData: {
            cursor: 0,
            size: 30,
            totalCount: 0,
          },
          ranks: [],
        },
        {
          id: 'team',
          thead: ['순위', '팀명', '참가 횟수', '승패', '획득 포인트', 'rankChange'],
          cursor: 0,
          size: 10,
          hasNext: false,
          totalCount: 0,
          myData: {
            cursor: 0,
            size: 30,
            totalCount: 0,
          },
          ranks: [],
        },
        {
          id: 'summoner-hall',
          ranks: [],
        },
      ],
    };
  },
  props: {
    leagueInfo: {
      type: Object,
      default: null,
    },
  },
  watch: {
    isLogin() {
      this.searchData();
    },
    officialTabIndex: 'changeTabIndex',
    searchInp() {
      if (!this.searchInp) {
        this.searchData();
      }
    },
  },
  computed: {
    isLogin() {
      return this.$store.getters['auth/isLogin'];
    },
    tabs() {
      const defaultTabs = ['대학 랭킹', '팀 랭킹'];
      return [...defaultTabs, '소환사의 전당'];
    },
    upRouteGameId() {
      return this.$route.params.id.toUpperCase();
    },
  },
  methods: {
    toggleTab(index) {
      let v;
      if (index === 0) v = 'univ';
      else if (index === 1) v = 'team';
      else if (index === 2) v = 'hall';
      this.trackEvent(`leaderboard_arena_${v}`, 'click', `${this.upRouteGameId}`);
    },
    async getCollegeRankData(getMyData = false) {
      if (!this.isLogin) getMyData = false;

      const collegeData = getMyData ? this.tabDatas[0].myData : this.tabDatas[0];
      const params = {
        universityName: this.searchInp,
        cursor: getMyData ? 0 : collegeData.cursor,
        size: collegeData.size,
      };

      try {
        if (!collegeData.loadedData) this.loadingData(true);
        const res = await this.$services.home.getUniversityRankings({
          isMy: getMyData,
          leagueCode: this.leagueInfo.leagueCode,
          params,
        });

        if (res.hasNext && !getMyData) collegeData.cursor = res.nextCursor;
        collegeData.hasNext = res.hasNext;
        collegeData.totalCount = res.totalCount;

        if (getMyData) {
          res.items = res.items.map(item => ({ ...item, myData: true }));
          this.tabDatas[0].ranks = res.items;

          await this.getCollegeRankData();
        } else {
          const ids = collegeData.ranks.map(rank => rank.university.universityId);
          res.items = res.items.filter(item => !ids.includes(item.university.universityId));

          collegeData.ranks = [...collegeData.ranks, ...res.items];
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.loadingData(false);
      }
    },
    async getTeamRankData(getMyData = false) {
      if (!this.isLogin) getMyData = false;

      const teamData = getMyData ? this.tabDatas[1].myData : this.tabDatas[1];

      const params = {
        teamName: this.searchInp,
        cursor: getMyData ? 0 : teamData.cursor,
        size: teamData.size,
      };

      try {
        if (!teamData.loadedData) this.loadingData(true);

        const res = await this.$services.home.getTeamRankings({
          isMy: getMyData,
          leagueCode: this.leagueInfo.leagueCode,
          params,
        });

        if (res.hasNext && !getMyData) teamData.cursor = res.nextCursor;
        teamData.hasNext = res.hasNext;
        teamData.totalCount = res.totalCount;

        if (getMyData) {
          res.items = res.items.map(item => ({ ...item, myData: true }));
          this.tabDatas[1].ranks = res.items;

          await this.getTeamRankData();
        } else {
          const ids = teamData.ranks.map(rank => rank.team.teamId);
          res.items = res.items.filter(item => !ids.includes(item.team.teamId));

          teamData.ranks = [...teamData.ranks, ...res.items];
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.loadingData(false);
      }
    },
    changeTabIndex() {
      this.searchInp = '';
      this.searchData();

      this.$router.replace({
        path: this.$route.path,
        query: { ...this.$route.query, official: this.officialTabIndex },
      });
    },
    searchData() {
      const { officialTabIndex } = this;

      if (officialTabIndex > 1) return;

      this.tabDatas[officialTabIndex].loadedData = false;
      this.tabDatas[officialTabIndex].cursor = 0;
      this.tabDatas[officialTabIndex].totalCount = 0;
      this.tabDatas[officialTabIndex].ranks = [];

      if (officialTabIndex === 0) {
        this.getCollegeRankData(true);
      } else if (officialTabIndex === 1) {
        this.getTeamRankData(true);
      }
    },
    moreLoadData() {
      const { officialTabIndex } = this;
      if (officialTabIndex === 0) {
        this.getCollegeRankData();
      } else if (officialTabIndex === 1) {
        this.getTeamRankData();
      }
    },
    loadingData(state) {
      const { officialTabIndex } = this;

      this.tabDatas[officialTabIndex].loadedData = true;
      this.loading = Boolean(state);
    },
  },
  async mounted() {
    this.officialTabIndex = Number(this.$route.query.official || 2);
    await this.getCollegeRankData(true);
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';

[official-league-rank] {
  .control-box { .mt(32); .clear;
    .toggle-tab-con { .fl; .w(100%);
      .tab { .ib-list; .rel; .-b(#ebebf0);
        button { width: calc(50% - 4px); .h(40); .tc; .br-t(4); .fs(14, 14); .regular; .c(rgba(0, 0, 0, 0.4)); .ls(-0.5); .bgc(#ebebf0); .rel;
          &.on { .bgc(black); .c(white); }
          &:nth-of-type(1) { .mr(8); }
        }
      }
      &.summoner {
        .tab {
          button { width: calc((100% / 3) - 4px); .mr(6);
            &:last-child { .mr(0); }
          }
        }
      }
    }
    .search-con { .fr; .w(100%); .m(20, 0);
      .search-input-box { .w(100%); }
      .search-input_input[type='text'] { .fs(13); .pr(70); }
      .search-input_button--search { .abs; .rt(0, 0); z-index: 1; .h(40); }
      .search-input_button--reset { .rt(34, -1); }
      .auto-complete { .z(3); }
    }
  }
  .tooltip-con { .rel; .z(2); .mb(3); .fs(13, 18); .medium; .c(#d13639); .tr;
    > span { .vam; }
    .tooltip { .ib; .ml(3);
      > img { .w(18); }
      .text-con { .lt(-223, 35); .w(242); .p(12, 16); .fs(12, 18); .c(rgba(255, 255, 255, 0.8)); .ls(-1); .tl;
        &::before { .l(94%); }
      }
    }
  }
  [no-data] { .br(0);
    [image-asset] > img { .max-w(50); }
    > p.text { .fs(16); .o(0.8); }
  }

  @media (@tl-up) {
    .control-box { .mb(40);
      .toggle-tab-con { .w(542); .mb(0);
        .tab {
          button { .wh(200, 48); .fs(16);
            &:nth-of-type(1) { .mr(8); }
          }
        }
        &.summoner { .w(620);
          .tab {
            button { width: calc((100% / 3) - 31px); }
          }
        }
      }
      .search-con { .w(300); .m(4, 0);
        .search-input_button--search { .bgc(#ebebf0); }
      }
    }
  }
  @media (@ds-up) {
    .control-box {
      .toggle-tab-con {
        &.summoner { .w(750); }
      }
    }
  }
}
</style>
