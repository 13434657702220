<template>
  <div tool-tip class="tooltip">
    <img :src="image" alt="tootip-image" />
    <span v-if="subject">{{ subject }}</span>
    <div class="text-con">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ToolTip',
  props: {
    image: {
      type: String,
      default: '/img/pages/icon/ico-question-mark.svg',
    },
    subject: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="less" scoped>
@import '~@/less/proj.less';

.tooltip { .rel; .pointer; }
.text-con { .abs; .lt(0, 0); z-index: 1; .max-w(240); .bgc(#3d3f45); .br(8); .o(0); .hidden;
  &::before { content: ''; .abs; .lt(51%, 16); z-index: 1; .wh(0); .mr(5); .t-y(-21); .-l(transparent, 4); .-r(transparent, 4); .-b(#3d3f45, 5); }
}
.tooltip {
  &:hover {
    .text-con { .visible; .o(1); transition: opacity 0.2s; }
  }
}
</style>
