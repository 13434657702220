<template>
  <div league-modal>
    <ModalTemplateOne ref="modalTemplate" :transition="'slide'">
      <ButtonRipple class="close-btn" @click="close">
        <img src="/img/pages/icon/ico-close-black.svg" alt="close" />
      </ButtonRipple>
      <div class="league-modal-con" v-if="baseInfo">
        <div v-if="onGoingLists.length" class="ing-leagues">
          <h6>진행 중인 대회</h6>
          <div class="list-con">
            <LeagueList
              v-for="league in onGoingLists"
              :key="'league-modal' + league.leagueCode"
              :league="league"
              :class="{'active': selectedLeagueCode === league.leagueCode}"
              @click.native="selectLeague(league)"
            />
          </div>
        </div>
        <div class="end-leagues">
          <h6>지난 대회</h6>
          <NoData v-if="!pastLists.length" text="지난 대회 목록이 없습니다." src="/img/asset/logo/ico-riot-dark-gray.svg"></NoData>
          <div v-else class="list-con">
            <LeagueList
              v-for="league in pastLists"
              :key="'league-modal' + league.leagueCode"
              :league="league"
              :class="{'active': selectedLeagueCode === league.leagueCode}"
              @click.native="selectLeague(league)"
            />
          </div>
        </div>
      </div>
    </ModalTemplateOne>
  </div>
</template>

<script>
import ModalTemplateOne from '@/views/components/common/modal/ModalTemplateOne.vue';
import ButtonRipple from '@/views/components/common/button/ButtonRipple.vue';
import NoData from '@/views/components/common/NoData.vue';

export default {
  name: 'LeagueModal',
  components: {
    LeagueList: () => import('../common/LeagueList'),
    ButtonRipple,
    ModalTemplateOne,
    NoData,
  },
  props: {
    options: {
      type: Object,
      default: () => {
      },
    },
  },
  data() {
    return {
      baseInfo: null,
    };
  },
  computed: {
    leagueLists() {
      return this.baseInfo ? this.baseInfo.leagueLists : [];
    },
    onGoingLists() {
      return this.leagueLists.filter(league => league.status === 'ONGOING');
    },
    pastLists() {
      return this.leagueLists.filter(league => league.status === 'END');
    },
    selectedLeagueCode() {
      return this.options.selectedLeague ? this.options.selectedLeague.leagueCode : null;
    },
  },
  methods: {
    close() {
      this.$refs.modalTemplate.selfClose();
    },
    selectLeague(leagueInfo) {
      this.$emit('resolve', leagueInfo);
    },
  },
  async created() {
    this.baseInfo = await this.$services.info.getBaseInfo(this.$route.params.id);
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';

[league-modal] {
  [modal-template-one] { .pt(70); .pb(0); .flex-ai(flex-end);
    .modal-con { .rel; .wh(100%); .p(44, 12, 28); .br-t(20);
      .close-btn { .abs; .rt(13, 11); z-index: 1; .wh(25); .br(50%); .bgc(#fff);
        > img { .wh(12); .vam; }
      }
      .league-modal-con { .h(100%); .-b(#ebebf0); overflow: auto;
        > div {
          &.ing-leagues { .mb(20); .pb(8); .-b(rgba(0, 0, 0, 0.2)); }
          > h6 { .mb(12); .fs(16, 23); .c(#000); .font-spoqa; }
          [league-list] { .mb(12); .pointer;
            &.active { .bgc(#ebebf0); .-a(#000, 2);
              &:hover { .bgc(#ebebf0); }
              &:active { .bgc(#eee); }
            }
            &:hover { .bgc(#f7f7f9); }
          }
        }
      }
    }
    [no-data] { .mt(5vh); .br(0);
      > .text { .c(rgba(0, 0, 0, 0.8)); }
      [image-asset] { .wh(60, 52);
        > img { .max-w(60); }
      }
    }
  }

  @media (@tp-up) {
    [modal-template-one] { .pt(100); .pb(100); .flex-ai(center);
      .modal-con { .max-w(628); .br(20);
        .close-btn { .t(8); box-shadow: none; }
        .league-modal-con { .-b(transparent); }
      }
    }
  }

  @media (@tl-up) {
    [modal-template-one] {.pt(150);.pb(150);
      .modal-con {.max-h(852); .p(52, 24, 40);
        .league-modal-con {
          > div {
            > h6 { .fs(20, 24); }
          }
        }
        .close-btn { .wh(21); .rt(24, 15);
          > img { .wh(15); }
        }
      }
    }
  }

  @media (@ds-up) {
    [modal-template-one] { .pt(100); .pb(100);
      .modal-con { .w(628); }
    }
  }
}
</style>
