<template>
  <PSection leader-board class="common-container">
    <SectionTitle title-ko="대회 결과" black sm />
    <ToggleTab
      v-model="mainTabSelectIndex"
      :tabs="['공식 대회 랭킹', '레벨업 랭킹']"
      @input="toggle"
    />
    <template v-if="baseInfo">
      <MatchSummary v-show="mainTabSelectIndex === 0" :league-info="leagueInfo" />
      <section class="context-data">
        <OfficialLeagueRank v-if="mainTabSelectIndex === 0" :league-info="leagueInfo" />
        <LevelupRank v-else :league-info="leagueInfo" />
      </section>
    </template>
  </PSection>
</template>

<script>
import _cloneDeep from 'lodash/cloneDeep';
import gtag from 'shared/mixins/gtag';
import ToggleTab from '@/views/components/common/tab/ToggleTab.vue';
import MatchSummary from '@/views/components/leaderboard/official-league-rank/MatchSummary.vue';
import OfficialLeagueRank from '@/views/components/leaderboard/official-league-rank/OfficialLeagueRank.vue';
import LevelupRank from '@/views/components/leaderboard/levelup-rank/LevelupRank.vue';
import meta from '@/data/meta';
import SectionTitle from '@/views/components/common/SectionTitle';

export default {
  name: 'LolLeaderboard',
  mixins: [gtag],
  components: { SectionTitle, ToggleTab, MatchSummary, OfficialLeagueRank, LevelupRank },
  data() {
    return {
      mainTabSelectIndex: 0,
      baseInfo: null,
    };
  },
  computed: {
    leagueInfo() {
      return this.baseInfo || {};
    },
    upRouteGameId() {
      return this.$route.params.id.toUpperCase();
    },
  },
  watch: {
    mainTabSelectIndex: 'mainTabChange',
  },
  methods: {
    selectLeague(leagueInfo = null) {
      if (leagueInfo) {
        this.leagueInfo = _cloneDeep(leagueInfo);
        this.fetchData();
      }
    },
    toggle(index) {
      let v;
      if (index === 0) v = 'arena';
      else if (index === 1) v = 'lvup';
      this.trackEvent(`leaderboard_${v}`, 'click', `${this.upRouteGameId}`);
    },
    mainTabChange() {
      this.$router.replace({
        path: this.$route.path,
        query: { tab: this.mainTabSelectIndex },
      });
    },
  },
  async mounted() {
    this.baseInfo = await this.$services.info.getBaseInfo(this.$route.params.id);
    this.mainTabSelectIndex = Number(this.$route.query.tab || 0);
    this.selectLeague();
  },
  metaInfo() {
    return meta[this.$route.params.id][this.$route.name];
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';

[leader-board] {
  .context-data { .min-h(500); .mb(70);
  }
  > .tab { .ib-list; .rel; .-b(#ebebf0);
    button { width: calc(50% - 4px); .h(40); .p(0, 11vw); .pl(0); .tr; .br-t(4); font-family: 'gmarket', sans-serif; .fs(14, 14); .semi-bold; .c(rgba(0, 0, 0, 0.4)); .ls(-0.5); .bgc(#ebebf0); .rel;
      &.on { .bgc(black); .c(white);}
      &:nth-of-type(1) { .mr(8);
        &.on {
          &::before {
            .bg('@{icon}/main-tab1-on.svg');
          }
        }
        &::before {
          .bg('@{icon}/main-tab1-off.svg');
        }
      }
      &:nth-of-type(2) {
        .pr(13vw);
        &.on {
          &::before {
            .bg('@{icon}/main-tab2-on.svg');
          }
        }

        &::before {
          .bg('@{icon}/main-tab2-off.svg');
        }
      }

      &::before { content: ''; .block; .wh(28, 28); .abs; .lt(10, -17); z-index: 1; .bg-c; .cover; .no-repeat;}
    }
  }

  @media (min-width: @screen-mm-min) {
    [section-title] {
      h2 .ko { .c(#000); .fs(20, 20); .bold; .ml(0); .mb(20); .mt(40);}
    }
    > .tab {
      button {
        &::before { .wh(32, 32); .lt(9%, -7);
        }
      }
    }
  }

  @media (@tp-up) {
    > .tab {
      button { .p(0); .tc;
        &:nth-of-type(2) { .pr(0);
        }
      }
    }
  }

  @media (@tl-up) {
    [section-title] {
      h2 {
        .ko { .fs(32, 32); .mb(40); }
      }
    }
    > .tab {
      button { .wh(292, 48); .fs(18);

        &:nth-of-type(1) { .mr(12);}
        &::before { .wh(44); .lt(13, -15);
        }
      }
    }
  }
  @media (@ds-up) {

    > .tab {
      button { .wh(400, 66); .fs(24);
        &:nth-of-type(1) { .mr(16);}
        &::before { .wh(66); .lt(13, -22);}
      }
    }
  }
}
</style>
