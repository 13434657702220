<template>
  <div responsive-container>
    <slot v-if="isPc" name="pc"></slot>
    <slot v-else name="mobile"></slot>
    <slot></slot>
  </div>
</template>

<script>
import _debounce from 'lodash/debounce';

export default {
  name: 'ResponsiveContainer',
  props: {
      breakPoint: {
          type: Number,
          default: 768
      },
  },
  data() {
      return {
          isPc: true
      }
  },
  mounted(){
      this.windowSizeCheck();

      window.addEventListener('resize', _debounce(this.windowSizeCheck, 50))
  },
  methods: {
      windowSizeCheck() {
          if(this.isPc && window.innerWidth <= this.breakPoint){
              this.isPc = false;
          }else if(!this.isPc && window.innerWidth > this.breakPoint){
              this.isPc = true;
          }
      }
  },
};
</script>

<style lang="less"></style>
