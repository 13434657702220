<template>
  <div list-format-wrap :class="['list-format', data.id]">
    <ul>
      <RankList
        v-for="(list, index) in lists"
        :key="data.id + '-rank-' + index"
        :list="list"
        :league-info="leagueInfo"
        :data="data"
        mode="mobile"
      />
    </ul>
  </div>
</template>

<script>
import RankList from '@/views/components/leaderboard/RankList.vue';

export default {
  name: 'ListFormatWrap',
  components: { RankList },
  props: {
    data: {
      type: Object,
      default: () => {
      },
    },
    leagueInfo: {
      type: Object,
      default: () => {
      },
    },
  },
  computed: {
    lists() {
      return this.data.ranks;
    },
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';

[list-format-wrap] { .mt(15);
  > ul {
    > li { .rel; .p(12, 10, 9); .pl(62); .mb(8); .br(4); .bgc(#ebebf0);
      .rank-info { .abs; .lt(12, 8); .z(1);
        .top-rank { .w(37); }
        .my-rank { .rel;
          em { .abs; .lt(-3, 8); .r(0); .z(1); .fs(12); .medium; .c(#fff); .tc; }
          > img { .w(37); }
        }
        > em { .wh(30); .ib; .font-russo; .fs(15.43, 30); .tc; box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.07); .br(8); .bgc(#d4d4d8); }
      }
      .team-info {
        .main { .rel;
          .subject { .rel;
            > p { .pr(130); .fs(16); .medium; .ellipsis(1); }
            > em { .abs; .rt(0, 5); .z(1); .block; .w(42); .fs(11, 13); .c(rgba(0, 0, 0, 0.5)); .ellipsis(1); }
            &.with-label { .mr(45); }
          }
          .flag {
            &.college-flag { .ib; .abs; .rt(40, 0); .z(1);
              > span { .max-w(87); .max-h(30); .p(4, 8); .fs(11, 13.2); .medium; .c(#000); .tc; .ellipsis(2); .bgc(#fff); .br(4); .o(0.7); }
            }
          }
          > span.rank-change { .ib; .abs; .rt(0, 0); .z(1); .w(33); .p(4, 0); .fs(11, 13.2); .medium; .c(#000); .tc; .bgc(rgba(255, 255, 255, 0.7)); .br(4);
            &::before { content: '-'; .ib; .mr(1); }
            &.up {
              &::before { content: ''; .wh(0); .mr(4); .t-y(-2); .-l(transparent, 4); .-r(transparent, 4); .-b(#d13639, 6); }
            }
            &.down {
              &::before { content: ''; .wh(0); .mr(4); .t-y(-2); .-l(transparent, 4); .-r(transparent, 4); .-t(#5b91f5, 6); }
            }
          }
        }
        .sub { .mt(11);
          > ul {
            > li { .ib; .mt(5); .mr(30);
              > span { .block; .fs(12, 14.4); .medium;
                &.label { .mb(4); .fs(11, 13.2); .c(#a5a5a8); .o(0.8);
                  &.red { .c(#d13639); }
                }
              }
            }
          }
        }
      }
      &.my { .bgc(#5b91f5);
        .team-info {
          .main {
            .subject {
              > p { .c(rgba(255, 255, 255, 0.9)); }
            }
          }
          .sub {
            > ul {
              > li {
                > span { .c(rgba(255, 255, 255, 0.9));
                  &.label { .c(rgba(0, 0, 0, 0.3));
                    &.red { .c(#d13639); }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  &.university {
    > ul {
      > li { .pointer;
        &.my {
          &:hover { .bgc(#5b91f5); }
          &:active { .bgc(#4e8afa); }
        }
        &:hover { .bgc(rgba(222, 222, 227, 0.8)); }
        &:active { .bgc(rgba(222, 222, 227, 1)); }
      }
    }
  }
}
</style>
