<template>
  <div team-rank>
    <SkeletonWrap v-if="loading" />
    <NoData v-else-if="!lists.length" :text="leagueInfo.status === 'PRE' ? '랭킹정보를 준비중입니다.' : '검색 결과가 없습니다.'"
      src="/img/asset/logo/ico-riot-dark-gray.svg"></NoData>
    <ResponsiveContainer v-else class="data-table-con" :break-point="800">
      <template v-slot:pc>
        <TableFormatWrap :data="data" />
      </template>
      <template v-slot:mobile>
        <ListFormatWrap :data="data" />
      </template>
      <div v-show="showMoreBtn" class="btn-more">
        <ButtonRipple @click="moreLoadData">더보기</ButtonRipple>
      </div>
    </ResponsiveContainer>
  </div>
</template>

<script>
import ButtonRipple from '@/views/components/common/button/ButtonRipple.vue';
import ResponsiveContainer from '@/views/components/common/ResponsiveContainer.vue';
import NoData from '@/views/components/common/NoData.vue';
import TableFormatWrap from '@/views/components/leaderboard/TableFormatWrap.vue';
import ListFormatWrap from '@/views/components/leaderboard/ListFormatWrap.vue';
import SkeletonWrap from '@/views/components/leaderboard/SkeletonListWrap.vue';

export default {
  name: 'TeamRank',
  components: {
    ButtonRipple,
    ResponsiveContainer,
    TableFormatWrap,
    ListFormatWrap,
    SkeletonWrap,
    NoData,
  },
  props: {
    data: {
      type: Object,
      default: () => {
      },
    },
    leagueInfo: {
      type: Object,
      default: () => {
      },
    },
    loading: {
      type: Boolean,
      default: false,
    },
    searchInp: {
      type: String,
      default: '',
    },
  },
  computed: {
    lists() {
      return this.data.ranks || [];
    },
    showMoreBtn() {
      return this.data.hasNext;
    },
  },
  methods: {
    moreLoadData() {
      this.$emit('more-load');
    },
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';

[team-rank] {
  [no-data] { .br(0);
    [image-asset] > img { .max-w(50); }
    > .text { .fs(16); .o(0.8); }
  }
  .data-table-con { .mb(28);
    .btn-more { .p(32, 0); .tc;
      > button { .wh(79, 40); .fs(16); .c(rgba(0, 0, 0, 0.4)); .bgc(#ebebf0); .br(34); }
    }
  }

  @media (@tl-up) {
    [no-data] { .br(0);
      [image-asset] > img { .max-w(60); }
    }
  }
}
</style>
