<template>
  <div skeleton-wrap>
    <div v-for="i in 4" :key="'lb-sceleton-' + i"></div>
  </div>
</template>

<script>
export default {
  name: 'LeaderBoardSkeletonWrap',
};
</script>

<style lang="less">
@import '~@/less/proj.less';

[skeleton-wrap] { .mt(15);
  > div { .wh(100%, 91); .mb(8); .bgc(#ebebf0); .br(4); .o(0.2); }

  @media (@tl-up) {
    .mt(12);
    > div { .h(72); .mb(12); }
  }
}
</style>
