<template>
  <div summoner-hall>
    <div class="border-con"></div>
    <div class="context">
      <div class="subject">
        <h1>소환사의 전당</h1>
        <p>라이엇 게임즈가 인정하는 최고의 소환사</p>
      </div>
      <div class="infor-con">
        <div class="deco-logo-img">
          <img src="/img/asset/logo/BattleAcademia.svg" alt="BattleAcademia" />
        </div>
        <button class="arenaInfo" @click="onClick">대회 정보</button>
        <div class="top-ranks">
          <div class="rank-con">
            <div class="rank-box">
              <img src="/img/pages/icon/Big-Rank1.svg" alt="" />
              <em>고려대학교</em>
            </div>
          </div>
          <div class="rank-con">
            <div class="rank-box">
              <img src="/img/pages/icon/Big-Rank2.svg" alt="" />
              <em>전남과학<br>대학교</em>
            </div>
            <div class="rank-box">
              <img src="/img/pages/icon/Big-Rank3.svg" alt="" />
              <em>영남대학교</em>
            </div>
          </div>
        </div>
        <div class="remain-ranks">
          <h2>파이널 토너먼트</h2>
          <ul class="team-li-con">
            <li>
              <div class="rank">
                <img src="/img/pages/graphic/emblem/team/1st.svg" alt="" />
              </div>
              <div class="team-name">
                CTU Pathos
                <span class="univ">
                  전남과학대학교
                </span>
              </div>
              <ul class="sommuner-li-con">
                <li>276뿡빵이158</li>
                <li>AIw4ys</li>
                <li>wossi</li>
                <li>비천혈검적월사신</li>
                <li>수 직</li>
                <li>쪼잔하게 굴지마</li>
              </ul>
            </li>
            <li>
              <div class="rank">
                <img src="/img/pages/graphic/emblem/team/2nd.svg" alt="" />
              </div>
              <div class="team-name">
                StarSpring
                <span class="univ">
                  수성대학교
                </span>
              </div>
              <ul class="sommuner-li-con">
                <li>Climb to rank 1</li>
                <li>Hoblt</li>
                <li>iM THE TREND1</li>
                <li>lure heart</li>
                <li>StarSpring</li>
                <li>담미온 부대국밥</li>
                <li>전서방 닭갈비</li>
              </ul>
            </li>
            <li>
              <div class="rank">
                <img src="/img/pages/graphic/emblem/team/3rd.svg" alt="" />
              </div>
              <div class="team-name">
                PTSD
                <span class="univ">
                  호서대학교
                </span>
              </div>
              <ul class="sommuner-li-con">
                <li>가장고민되는것</li>
                <li>사 윤</li>
                <li>샤코 공주님</li>
                <li>세젤귀창호</li>
                <li>아픈건싫으니까방어력에올인할려고</li>
                <li>와드 제조자</li>
                <li>흑소한마리</li>

              </ul>
            </li>
            <li>
              <div class="rank">
                <img src="/img/pages/graphic/emblem/team/4th.svg" alt="" />
              </div>
              <div class="team-name">
                MNGD
                <span class="univ">
                  경기대학교
                </span>
              </div>
              <ul class="sommuner-li-con">
                <li>DWG J</li>
                <li>Mute and Game</li>
                <li>내 이름은 박효빈</li>
                <li>봄 꽃 차</li>
                <li>신난담</li>
              </ul>
            </li>
            <li>
              <div class="rank">
                <img src="/img/pages/graphic/emblem/team/5th.svg" alt="" />
              </div>
              <div class="team-name">
                CTU_Logos
                <span class="univ">
                  전남과학대학교
                </span>
              </div>
              <ul class="sommuner-li-con">
                <li>Jollin</li>
                <li>Querella</li>
                <li>동그리동환</li>
                <li>물대신음료수</li>
                <li>반으로 접어준다</li>
                <li>쵸비만큼</li>
                <li>호빵맨머리미식가</li>
              </ul>
            </li>
            <li>
              <div class="rank">
                <img src="/img/pages/graphic/emblem/team/5th.svg" alt="" />
              </div>
              <div class="team-name">
                PNU-ULTIMATE
                <span class="univ">
                  부산대학교
                </span>
              </div>
              <ul class="sommuner-li-con">
                <li>AriableyeS</li>
                <li>Blessing U</li>
                <li>SunEN</li>
                <li>날카로운 킬각</li>
                <li>부산대 낭만파</li>
              </ul>
            </li>
            <li>
              <div class="rank">
                <img src="/img/pages/graphic/emblem/team/5th.svg" alt="" />
              </div>
              <div class="team-name">
                EagleOwls
                <span class="univ">
                  호남대학교
                </span>
              </div>
              <ul class="sommuner-li-con">
                <li>Chongwu</li>
                <li>nwn</li>
                <li>순찰관</li>
                <li>이주녁1</li>
                <li>호철짱</li>
              </ul>
            </li>
            <li>
              <div class="rank">
                <img src="/img/pages/graphic/emblem/team/5th.svg" alt="" />
              </div>
              <div class="team-name">
                JDG
                <span class="univ">
                  인천재능대학교
                </span>
              </div>
              <ul class="sommuner-li-con">
                <li>강문아넌할수있어</li>
                <li>궁합류</li>
                <li>박씅원</li>
                <li>엑 두</li>
                <li>인천대표정글</li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import gtag from '@shared/mixins/gtag';

export default {
  name: 'SummonerHall',
  mixins: [gtag],
  computed: {
    upRouteGameId() {
      return this.$route.params.id.toUpperCase();
    },
  },
  methods: {
    onClick() {
      this.trackEvent('LOL_leaderboard_arena_hall_btn', 'click', 'LOL');
      this.$router.push({ name: 'AcademyLanding' });
    },
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[summoner-hall] {.rel; .m(40, 0, 60); .p(60, 0); .bgc(rgba(2, 14, 56, 1)); .cover('@{bg}/summoner-hall-bg.png'); background-position: center top;
  .border-con {.abs; .lt(0, 0); .r(0); z-index: 1; .wh(100%); .hide;
    &::before {content: ''; .block;.abs;.lt(0, 0);z-index: 1; width: calc(100% - 38px); height: calc(100% - 46px); .m(23, 19); .-a(#403e3d, 3px);}
    &::after {content: '';.block;.abs;.lt(0, 0);z-index: 1;width: calc(100% - 52px);height: calc(100% - 58px);.m(31, 28);.-a(#403e3d, 1px);}
  }
  .context {.rel;z-index: 2;
    .subject { .font-g;.tc;
      > h1 {.mt(18);.mb(4);.fs(32, 41);.c(#fff);.ls(-2);}
      > p {.mb(18);.fs(14, 18);.ls(-1);.c(#7e724f);}
      &::before { content: '';.block;.h(12);.contain('@{icon}/summoner-hall-subject-top.svg');.no-repeat;.bg-c}
      &::after {content: '';.block;.h(12);.contain('@{icon}/summoner-hall-subject-bottom.svg');.no-repeat;.bg-c;}
    }
    .infor-con { .tc;
      .deco-logo-img {.tc;
        > img {.wh(199, 152);.m(43, 0, 68);}
      }
      .arenaInfo { .wh(120, 40); .bgc(rgba(30, 84, 183, 1)); .fs(14, 14px); .br(8); .c(rgba(255, 255, 255, 0.8)); .tc; .mb(40);}
    }
    .top-ranks {.tc;
      .rank-con {.ib;.tc;
        .rank-box {.rel;.ib;.m(0, 15, 40);
          > img { .wh(251, 348);}
          > em {.abs;.lt(0, 168);z-index: 1;.w(100%);font-family: 'gmarket', sans-serif;.fs(18);.c(#fff);}
        }
      }
    }
    .remain-ranks {
      h2 {.mt(100);.mb(43);.fs(24, 33);.regular;.c(#fff);.tc;
        &::before,
        &::after {content: '';.ib;.wh(20, 33);.m(0, 15);.vam;.contain('@{icon}/sommuner-sub-title-arrow.svg');.no-repeat;.bg-c;}
        &::after {transform: rotate(180deg);}
      }
      .team-li-con {.max-w(389);.mh-c;.tc;
        > li {.ib;.w(117);.m(0, 20, 40); .vam;
          .rank {.rel;
            > em {.abs;.lt(0, 19);z-index: 1;.w(100%);.fs(24, 29);.c(#ebebf0); }
          }
          .team-name {.m(27, 0, 26);.fs(16);.c(rgba(255, 255, 255, 0.8));.tc;
            .univ { .fs(12, 14.4px); .regular; .o(.4); .block; .mt(4);}
            &::after {content: '';.block;.mh-c;.mt(22);.wh(24, 1);.bgc(#cfd0d5);}
          }
          .sommuner-li-con {.min-h(170);.o(0.5);
            > li {.mb(12);.fs(12, 14.4);.c(rgba(255, 255, 255, 0.8));.tc;.ellipsis(1);
              &:last-child {.mb(0);
              }
            }
          }
        }
      }
    }
  }
  @media (@tl-up) {
    .m(65, 0, 130);.p(72, 0);
    .border-con {.block;}
    .context {
      .subject {
        &::before,
        &::after {.h(32);}
        > h1 {.mt(20);.fs(52, 68);}
        > p {.mb(20);.fs(20, 26);}
      }
      .infor-con {
        .deco-logo-img {
          > img {.mt(45);.mb(20);
          }
        }
      }
      .top-ranks {
        .rank-con {
          .rank-box {

          }
        }
      }
      .remain-ranks {
        h2 {.mb(47);.fs(32, 38);.ls(-2);
          &::before,
          &::after {.wh(25, 41);}
        }
        .team-li-con {.max-w(641);
        }
      }
    }
  }
  @media (@ds-up) {
    .p(83, 0);

    .context {
      .remain-ranks {
        .team-li-con {
          .max-w(1200);
          > li { .mr(16); .ml(16);}
        }
      }
    }
  }
}
</style>
