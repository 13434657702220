<template>
  <div rank-modal>
    <ModalTemplateOne ref="modalTemplate" :transition="'slide'">
      <div class="rank-modal-con">
        <div class="hd">
          <div class="rank-info">
            <img v-if="collegeRanking <= 3" :src="`/img/pages/icon/Rank_${collegeRanking}.svg`" class="top-rank" />
            <div v-else-if="myCollege" class="my-rank">
              <em>{{ collegeRanking }}</em>
              <img src="/img/pages/icon/Rank_My.svg" />
            </div>
            <em v-else>{{ collegeRanking }}</em>
          </div>
          <span>{{ collegeName }}</span>
          <ButtonRipple class="close-btn" @click="close">
            <img src="/img/pages/icon/ico-close-black.svg" alt="close" />
          </ButtonRipple>
        </div>
        <div class="bd">
          <table>
            <thead>
              <tr>
                <th>순위</th>
                <th>팀명</th>
                <th>참여 수</th>
                <th>경기</th>
                <th>획득 포인트</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="team in topTeams" :key="'teams-' + team.teamid">
                <td>{{ team.ranking }}</td>
                <td>
                  <div>{{ team.name }}</div>
                </td>
                <td>{{ team.applycount }}</td>
                <td>{{ team.winorlosecnt }}</td>
                <td>{{ team.point }}p</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </ModalTemplateOne>
  </div>
</template>

<script>
import { commaDecimal } from '@/utils';
import ButtonRipple from '@/views/components/common/button/ButtonRipple.vue';
import ModalTemplateOne from '@/views/components/common/modal/ModalTemplateOne.vue';

export default {
  name: 'RankModal',
  components: { ButtonRipple, ModalTemplateOne },
  data() {
    return {
      college: {},
      leagueCode: '',
      topTeams: [],
    };
  },
  props: {
    options: {
      type: Object,
      default: () => {
      },
    },
  },
  computed: {
    collegeName() {
      return this.college.university ? this.college.university.name : '';
    },
    myCollege() {
      return this.college.myData;
    },
    collegeRanking() {
      return this.college.ranking;
    },
  },
  methods: {
    async getTopRankData() {
      const params = { size: 5 };
      const res = await this.$services.leaderboard.getTopRanks({ leagueId: this.leagueCode, rankId: this.college.universityRankingId, params });

      this.topTeams = res.map(team => ({
          ...team.team,
          ranking: team.ranking,
          point: commaDecimal(team.stats.point),
          winOrloseCnt: `${team.stats.winCount}승 ${team.stats.loseCount}패`,
          applyCount: commaDecimal(team.stats.applyCount),
        }));
    },
    close() {
      this.$refs.modalTemplate.selfClose();
    },
  },
  mounted() {
    this.college = this.options.college;
    this.leagueCode = this.options.leagueCode;

    this.getTopRankData();
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';

[rank-modal] {
  [modal-template-one] {
    .modal-con { .max-h(425); .p(23, 4%, 32); .br-t(20);
      .rank-modal-con { .h(100%);
        .hd { .rel; .pb(10); .-b(#ebebf0);
          .rank-info { .ib;
            .top-rank { .w(40); .mb(-7); }
            .my-rank { .rel;
              em { .abs; .lt(0, 6.5); .r(0); z-index: 1; .fs(13); .c(#fff); .tc; }
              > img { .w(40); .mb(-7); }
            }
            > em { .wh(40); .ib; .font-russo; .fs(16, 40); .tc; box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.07); .br(8); .bgc(#d4d4d8); }
          }
          > span { .fs(16, 23); .bold; .ml(12); }
          .close-btn { .abs; .rt(0, 5); z-index: 1; .wh(25); .br(50%); .bgc(#fff); .o(0.7);
            > img { .wh(12); .vam; }
          }
        }
        .bd { .pt(14); height: calc(100% - 52px); overflow: auto;
          table { table-layout: fixed;
            thead { .bgc(#ebebf0);
              tr {
                th { .p(7px, 10); .c(#a4a3ae); }
              }
            }
            tbody {
              tr {
                td { .h(55); .p(10, 10); .-b(rgba(0, 0, 0, 0.1)); }
              }
            }
            th, td { .fs(12); .tc;
              &:nth-of-type(2) { .w(30%); .tl;
                > div { .ellipsis(3); }
              }
              &:nth-of-type(4) { }
            }
          }
        }
      }
    }
  }

  @media (@tp-up) {
    [modal-template-one] {
      .modal-con { .wh(600, 425); .pl(20); .pr(20); .br(20); }
    }
  }
}
</style>
