<template>
  <table table-format-wrap :class="['table-format', data.id]">
    <thead>
      <tr>
        <th
          v-for="(th, index) in thead"
          :key="data.id + index"
          :class="{ 'rank-change': th === 'rankChange', red: th === '레벨업 MMR' }"
        >
          {{ th !== 'rankChange' ? th : '' }}
        </th>
      </tr>
    </thead>
    <tbody>
      <RankList v-for="(list, index) in lists" :key="data.id + '-rank-' + index" :list="list" :league-info="leagueInfo"
        :data="data" />
    </tbody>
  </table>
</template>

<script>
import RankList from '@/views/components/leaderboard/RankList.vue';

export default {
  name: 'TableFormatWrap',
  components: { RankList },
  props: {
    data: {
      type: Object,
      default: () => {
      },
    },
    leagueInfo: {
      type: Object,
      default: () => {
      },
    },
  },
  computed: {
    thead() {
      return this.data.thead;
    },
    lists() {
      return this.data.ranks;
    },
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';

[table-format-wrap] { table-layout: fixed; border-collapse: separate; border-spacing: 0 12px;
  thead { .bgc(rgba(235, 235, 240, 0.6));
    &::after { content: ''; .block; .wh(100%, 0); .mt(-2); .bgc(#fff); }
    th { .h(40); .c(rgba(0, 0, 0, 0.8));
      &.red { .c(#d13639); }
      &.rank-change {
        .tooltip {
          .text-con { .l(-201);
            &::before { .l(84%); }
          }
        }
      }
    }
  }
  tbody {
    tr { .h(72); .-b(#fff, 12); .bgc(#ebebf0); .br(4);
      &.my { .bgc(#5b91f5);
        td { .regular; .c(#fff);
          .flag {
            &.college-flag { .c(rgba(255, 255, 255, 0.5)); }
          }
        }
      }
      td {
        &:first-child { border-radius: 4px 0 0 4px; }
        &:last-child { border-radius: 0 4px 4px 0; }
        &.point {}
        .top-rank { .w(50); .mt(5); }
        .my-rank { .rel;
          em { .abs; .lt(-5, 16); .r(0); z-index: 1; .fs(16); .c(#fff); }
          > img { .w(50); .mt(5); }
        }
        > em { .wh(40); .ib; .font-russo; .fs(20, 40); box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.07); .br(8); .bgc(#d4d4d8); }
      }
    }
  }
  &.university {
    tbody {
      tr { .pointer;
        &.my {
          &:hover { .bgc(#5b91f5); }
          &:active { .bgc(#4e8afa); }
        }
        &:hover { .bgc(rgba(222, 222, 227, 0.8)); }
        &:active { .bgc(rgba(222, 222, 227, 1)); }
      }
    }
  }
  th, td { .w(92); .fs(13); .medium; .tc;
    &:nth-of-type(2) { width: 56%; .p(0, 6); .tl;
      .team-name { .ellipsis(1); .vam;
        &.with-flag { .ib; max-width: 70%; .pr(10); }
        .team-logo { .mr(12); .br(4); .vam; .hide; }
      }
      .flag { .ib; .h(24); .mt(-4); .p(0, 8); .fs(12, 24); .medium; .c(#00af90); .tc; .bgc(#e6f2ed); .-a(rgba(2, 159, 132, 0.1)); .br(4);
        &.my-flag { .c(#5b91f5); .bgc(#d4e2fc); .-a(rgba(84, 132, 222, 0.1)); }
        &.college-flag { .c(rgba(0, 0, 0, 0.5)); .-a(transparent); .bgc(transparent); }
      }
    }
    &.rank-change { .w(64); .tl;
      span { .ib; .wh(38, 24); .p(4, 0); .fs(12, 15); .regular; .c(#000); .tc; .bgc(rgba(255, 255, 255, 0.7)); .br(4);
        &::before { content: '-'; .ib; .mr(1); }
      }
      &.up {
        span {
          &::before { content: ''; .wh(0); .mr(5); .t-y(-2); .-l(transparent, 4); .-r(transparent, 4); .-b(#d13639, 6); }
        }
      }
      &.down {
        span {
          &::before { content: ''; .wh(0); .mr(5); .t-y(-2); .-l(transparent, 4); .-r(transparent, 4); .-t(#5b91f5, 6); }
        }
      }
    }
  }
  &.levelup-team {
    th, td {
      &:last-child { .w(140); .pr(40); }
    }
  }

  @media (@tl-up) {
    th,
    td {
      &:nth-of-type(2) {
        .team-name {
          .team-logo { .ib; }
        }
      }
    }
  }

  @media (@ds-up) {
    thead {
      th { .h(40); }
    }

    th,
    td { .w(103); .fs(15);
      &:nth-of-type(2) { .w(56%); }
    }
  }
}
</style>
