<template>
  <div levelup-rank>
    <div class="control-box">
      <div class="search-con">
        <SearchInput v-model="searchInp" :placeholder="'검색어를 입력해주세요.'" @click="searchData" />
      </div>
    </div>
    <div class="tooltip-con">
      <span>레벨업 랭킹 정보</span>
      <ToolTip image="/img/pages/icon/ico-question-mark-red.svg">
        *레벨업 MMR은 플랫폼 고유 MMR이며, 레벨업 플랫폼에서 진행한 경기에 따른 수치를 나타냅니다. <br /> *공식 대회 매칭 시에는 매칭 MMR이 적용되며, 레벨업 MMR은 적용되지 않습니다. <br /> *순위는 경기 종료 후 수 분 내에 업데이트 됩니다. <br />
      </ToolTip>
    </div>
    <SkeletonWrap v-if="loading" />
    <NoData
      v-else-if="!rankData.ranks.length"
      :text="noDataText"
      src="/img/asset/logo/ico-riot-dark-gray.svg"
    ></NoData>
    <ResponsiveContainer v-else class="data-table-con" :break-point="800">
      <template v-slot:pc>
        <TableFormatWrap :data="rankData" />
      </template>
      <template v-slot:mobile>
        <ListFormatWrap :data="rankData" />
      </template>
      <div v-show="rankData.hasNext" class="btn-more">
        <ButtonRipple @click="moreLoadData">더보기</ButtonRipple>
      </div>
    </ResponsiveContainer>
  </div>
</template>

<script>
import { getter } from '@shared/utils/storeUtils';
import SearchInput from '@/views/components/common/input/SearchInput.vue';
import ButtonRipple from '@/views/components/common/button/ButtonRipple.vue';
import ToolTip from '@/views/components/common/ToolTip.vue';
import ResponsiveContainer from '@/views/components/common/ResponsiveContainer.vue';
import TableFormatWrap from '@/views/components/leaderboard/TableFormatWrap.vue';
import ListFormatWrap from '@/views/components/leaderboard/ListFormatWrap.vue';
import SkeletonWrap from '@/views/components/leaderboard/SkeletonListWrap.vue';
import NoData from '@/views/components/common/NoData.vue';

export default {
  name: 'LevelupRank',
  components: {
    SearchInput,
    ButtonRipple,
    ToolTip,
    ResponsiveContainer,
    TableFormatWrap,
    ListFormatWrap,
    SkeletonWrap,
    NoData,
  },
  data() {
    return {
      loading: false,
      searchInp: '',
      rankData: {
        id: 'levelup-team',
        thead: ['순위', '레벨업지지 닉네임', '참가 횟수', '승패', '레벨업 MMR'],
        cursor: 0,
        size: 10,
        hasNext: false,
        totalCount: 0,
        myData: {
          cursor: 0,
          size: 30,
          totalCount: 0,
        },
        ranks: [],
      },
    };
  },
  props: {
    leagueInfo: {
      type: Object,
      default: null,
    },
  },
  computed: {
    isLogin: getter('auth', 'isLogin'),
    noDataText() {
      if (this.leagueInfo.status === 'PRE') {
        return '랭킹정보를 준비중입니다.';
      } if (this.searchInp.length > 0) {
        return '검색 결과가 없습니다.';
      }

      return '랭킹 정보가 없습니다.';
    },
  },
  watch: {
    isLogin: 'searchData',
    searchInp() {
      if (!this.searchInp) {
        this.searchData();
      }
    },
  },
  methods: {
    async getTeamRankData(getMyData = false) {
      if (!this.isLogin) getMyData = false;

      const rankData = getMyData ? this.rankData.myData : this.rankData;
      const params = {
        userName: this.searchInp,
        cursor: getMyData ? 0 : rankData.cursor,
        size: rankData.size,
      };

      try {
        if (!this.rankData.loadedData) this.loadingData(true);
        const res = await this.$services.leaderboard.getUserRanks({ isMy: getMyData, params });

        if (res.hasNext && !getMyData) rankData.cursor = res.nextCursor;
        rankData.hasNext = res.hasNext;
        rankData.totalCount = res.totalCount;

        if (getMyData) {
          res.items = res.items.map(item => ({ ...item, myData: true }));
          rankData.ranks = [...res.items];

          await this.getTeamRankData();
        } else {
          const ids = rankData.ranks.map(rank => rank.user.userId);
          res.items = res.items.filter(item => !ids.includes(item.user.userId));

          rankData.ranks = [...rankData.ranks, ...res.items];
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.loadingData(false);
      }
    },
    searchData() {
      this.rankData.cursor = 0;
      this.rankData.totalCount = 0;
      this.rankData.ranks = [];

      this.getTeamRankData(true);
    },
    moreLoadData() {
      this.getTeamRankData();
    },
    loadingData(state) {
      this.rankData.loadedData = true;
      this.loading = Boolean(state);
    },
  },
  mounted() {
    this.getTeamRankData(true);
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';

[levelup-rank] { .mt(0);
  .control-box { .mt(10); .clear;
    .toggle-tab-con { .fl; .w(100%);
      .tab { .ib-list; .rel; .-b(#ebebf0);
        button { width: calc(50% - 4px); .h(40); .tc; .br-t(4); .fs(14, 14); .regular; .c(rgba(0, 0, 0, 0.4)); .ls(-0.5); .bgc(#ebebf0); .rel;
          &.on { .bgc(black); .c(white); }
          &:nth-of-type(1) { .mr(8); }
        }
      }
    }
    .search-con { .fr; .w(100%); .m(20, 0);
      .search-input-box { .w(100%); }
      .search-input_input[type='text'] { .fs(13); .pr(70); }
      .search-input_button--search { .abs; .rt(0, 0); z-index: 1; .h(40); }
      .search-input_button--reset { .rt(34, -1); }
    }
  }
  .tooltip-con { .rel; z-index: 2; .mb(3); .fs(13, 18); .medium; .c(#d13639); .tr;
    > span { .vam; }
    .tooltip { .ib; .ml(3);
      > img { .w(18); }
      .text-con { word-break: normal; .lt(-223, 35); .w(242); .p(12, 16); .fs(12, 18); .c(rgba(255, 255, 255, 0.8)); .ls(-1); .tl;
        &::before { .l(94%); }
      }
    }
  }
  [no-data] { .br(0);
    [image-asset] > img { .max-w(50); }
    > p.text { .fs(16); .o(0.8); }
  }
  .data-table-con { .mb(28);
    .btn-more { .p(32, 0); .tc;
      > button { .wh(79, 40); .fs(16); .c(rgba(0, 0, 0, 0.4)); .bgc(#ebebf0); .br(34); }
    }
  }

  @media (@tl-up) {
    .mt(60);
    .control-box { .mb(40);
      .toggle-tab-con { .w(542); .mb(0);
        .tab {
          button { .wh(200, 48); .fs(16);
            &:nth-of-type(1) { .mr(8); }
          }
        }
      }
      .search-con { .w(300); .m(4, 0);
        .search-input_button--search { .bgc(#ebebf0); }
      }
    }
    [no-data] { .br(0);
      [image-asset] > img { .max-w(60); }
    }
  }
  @media (@ds-up) {
    .control-box {
      .toggle-tab-con {
      }
    }
  }
}
</style>
