<template>
  <div match-summary>
    <div class="summary-con">
      <!-- <LeagueList class="box-left" :league="leagueInfo" :selected-league="leagueInfo" @select-league="selectLeague" /> -->
      <LeagueList class="box-left" :league="leagueInfo" @select-league="selectLeague" />
      <div class="box-right">
        <div class="label">총 출전 수</div>
        <div class="count-con">
          <CountView title="출전 대학" :length="3" :value="collegeCount" :prxs="collCardPrx" />
          <div class="middle-line"></div>
          <CountView title="출전 팀" :length="4" :value="teamCount" :prxs="collCardPrx" />
        </div>
      </div>
    </div>
    <div v-if="isLogin" class="my-info">
      <div class="info-context">
        <div class="nickname">
          <ImgLogo class="user-image" :source="myInfo.information.image" :title="myInfo.nickname" :hash="`${myInfo.id}`" />
          <span>{{ myInfo.nickname }}</span>
        </div>
        <div class="context">
          <ul>
            <li>
              <em>참여 수</em> {{ myJoinedCnt }}
            </li>
            <li>
              <em>승패</em> {{ myWinOrLoseCnt }}
            </li>
            <li>
              <em>획득 포인트</em> {{ myPoint }}P
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div v-else class="my-info-guest">
      <ButtonRipple @click="loginModal">
        <img src="/img/pages/icon/login-white.svg" alt="login button" />
        로그인 후 개인 포인트 확인
      </ButtonRipple>
    </div>
  </div>
</template>

<script>
import { getter, state } from '@shared/utils/storeUtils';
import { commaDecimal } from '@/utils';
import LeagueList from '@/views/components/common/LeagueList.vue';
import CountView from '@/views/components/common/CountView.vue';
import ImgLogo from '@/views/components/common/ImgLogo.vue';
import ButtonRipple from '@/views/components/common/button/ButtonRipple.vue';

export default {
  name: 'MatchSummary',
  components: { CountView, LeagueList, ImgLogo, ButtonRipple },
  data() {
    return {
      collCardPrx: [
        { r: [4, 8], ty: [50, 0] },
        { r: [4.5, 9], ty: [-50, 0] },
        { r: [5, 10], ty: [50, 0] },
      ],
      collegeCount: 0,
      teamCount: 0,
      myStats: null,
    };
  },
  props: {
    leagueInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    myInfo: state('auth', 'myInfo'),
    isLogin: getter('auth', 'isLogin'),
    myPoint() {
      return this.myStats ? commaDecimal(this.myStats.point) : 0;
    },
    myWinOrLoseCnt() {
      return this.myStats ? `${this.myStats.winCount}승 ${this.myStats.loseCount}패` : '0승 0패';
    },
    myJoinedCnt() {
      return this.myStats ? commaDecimal(this.myStats.applyCount) : 0;
    },
  },
  watch: {
    leagueInfo: 'fetchData',
  },
  methods: {
    async fetchData() {
      if (!this.leagueInfo.leagueCode) return;
      const [summaryData, myData] = (await Promise.all([
        this.getSummaryData(),
        this.getMyData(),
      ])).map(res => res);

      this.collegeCount = summaryData.groupCount;
      this.teamCount = summaryData.teamCount;

      this.myStats = myData.stats;
    },
    selectLeague(leagueInfo) {
      this.$emit('select-league', leagueInfo);
    },
    getSummaryData() {
      return this.$services.leaderboard.getMatchSummary(this.leagueInfo.leagueCode);
    },
    getMyData() {
      if (!this.isLogin) return false;
      return this.$services.leaderboard.getMyLeague(this.leagueInfo.leagueCode);
    },
    async loginModal() {
      await this.$services.auth.oAuthLogin(true);
    },
  },
  mounted() {
    this.fetchData();
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[match-summary] { .m(33, 0, 0);
  .summary-con { .crop;
    > div { .rel; .w(100%); .mb(12); .p(10); .-a(rgba(0, 0, 0, 0.2)); .br(4);
      &.box- {
        &left { }
        &right { .tc;
          > div { .ib; }
          .label { .w(125); .hide; }
          .count-con { .inline-flex; justify-content: space-around; .w(100%); .max-w(506);
            [count-view] { .m(0, 6); .pt(21);
              &:nth-of-type(1) { .ml(0); }
              &:nth-of-type(2) { .mr(0); }
              > p { .fs(12); .mb(0); .o(0.6); }
              > ul {
                > li { .wh(32, 42); .c(#ec5355); .bgc(#ebebf0);
                  > p { .fs(24); }
                }
              }
            }
            .middle-line { .rel; .wh(1, 63);
              &::before { .cnt; .abs; .lb(0, 0); .z(1); .wh(1, 42); .bgc(#191919); .o(0.2); }
            }
          }
        }
      }
    }
  }
  .my-info { .rel; .h(60); .p(10); .pl(36); .-a(#5b91f5); .br(4); .crop;
    &::before { content: 'MY'; .abs; .lt(0, 0); z-index: 1; .block; .wh(36, 60); .p(22, 0); .fs(12); .c(#fff); .tc; .bgc(#5b91f5); .-box; }
    .info-context {
      .nickname { .hide; }
      .context {
        > ul { .crop;
          > li { .fl; width: calc(100% / 3); .tc; .fs(14); .bold;
            > em { .block; .mb(7); .fs(11); .regular; .c(#a4a3ae); }
          }
        }
      }
    }
  }
  .my-info-guest {
    > button { .br(3); .wh(100%, 60); .fs(14, 60); .c(#fff); background: linear-gradient(135deg, #2c81ff 0%, #b0aaff 100%);
      > img { .mr(10); }
    }
  }

  @media (@tl-up) {
    .m(59, 0, 60);
    .summary-con {
      > div { .fl; width: calc(50% - 7.5px); .p(12); .mb(20);
        &:nth-of-type(1) { .mr(15); }
        &.box- {
          &right { .p(18, 12);
            .count-con {
              [count-view] { .pt(30);
                > p { .fs(14); }
                > ul {
                  > li { .wh(44, 52);
                    > p { .fs(28); }
                  }
                }
              }
              .middle-line { .h(72);
                &::before { .h(52); }
              }
            }
          }
        }
      }
    }
    .my-info { .h(72);
      &::before { .h(72); .p(28, 0); }
      .info-context {
        > div { .ib;
          &.nickname { .rel; .ib; .w(266); .p(0, 25); .mr(30); .vam;
            &::after { .cnt; .abs; .rt(0, 9); .z(1); .wh(1, 32); .bgc(#5b91f5); }
            .user-image { .wh(48, 48); .m(1, 0); .br(50%); .vam; }
            > span { .fs(16); .ml(12); .ib; }
          }
          &.context { width: calc(100% - 296px); .max-w(450); .vam;
            > ul {
              > li { .fs(16);
                > em { .fs(14); }
              }
            }
          }
        }
      }
    }
    .my-info-guest {
      > button { .wh(100%, 72); .fs(18, 72); .c(#fff); background: linear-gradient(135deg, #2c81ff 0%, #b0aaff 100%);
        > img { .w(20); .mr(23); }
      }
    }
  }

  @media (@ds-up) {
    .m(61, 0, 60);
  }
}
</style>
